<template>
  <div>
    <div class="page">
      <div class="herd">
        <div class="herd1">
          <div :class="['herdtext1',show==0?'ys':'']" @click="onxz1()">
            <div>首页</div>
            <div class="span" v-if="show==0"></div>
          </div>
          <div :class="['herdtext2',show==1?'ys':'']" @click="onxz2()">
            <div>关于我们</div>
            <span class="span1" v-if="show==1"></span>
          </div>
        </div>
        <div class="herd2">不止心动（成都）网络科技有限公司</div>
      </div>
      <!-- 首页 -->
      <div class="nr1" v-if="show==0" @click="onxiaz()">
        <img :src="img" class="nr1img" />
      </div>
      <!-- 关于我们 -->
      <div class="nr2" v-if="show==1">
        <div class="nr2text">联系方式：hnndkf@163.com</div>
      </div>
      

      <div class="foot">
        <div class="foot-text1">
          <a  href="https://beian.miit.gov.cn/" target="_blank" >{{ icp }}</a>
          </div>
    
        <div class="foot-text2" v-if="img=='https://cdn.bzxd.tv/mydemo/demoimg1.png'" >
            <span class="police" >
                <img src="../assets/images/police.png" alt="">
            </span>
            <a  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956" target="_blank" >沪公网安备 31011502019956号</a>
        </div>
        <div class="foot-text3">@2022—不止心动（成都）网络科技有限公司</div>
      </div>
    </div>
  </div>
</template>
<script>
import { AppInfo } from "../utils/Host";

export default {
  data() {
    return {
        show:0,
        img:null
    };
  },
created() {
 
  this.img=AppInfo.imgage
  this.icp=AppInfo.icp

},
  methods:{
    onxz1(){
this.show=0
    },
    onxz2(){
this.show=1
    },
    //下载
    onxiaz(){
      const fileUrl = 'https://cdn.bzxd.tv/package/build/apk/tl_huawei/release/taliao_tl_huaweiRelease_1.3.1_tl_huawei_20230626-19-34.apk';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'taliao_tl_huaweiRelease.apk');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log('下载中');
    }
  }
};
</script>
<style scoped>
.herd {
  width: 100%;
  height: 90px;
  background-color: #fff;
  display: flex;
  font-size: 24px;
  position: relative;
  color: #B5B5B5;
}
.herd1 {
  display: flex;
}
.herdtext1 {
  position: absolute;
  top: 30px;
  left: 67px;
}
.herdtext2 {
  position: absolute;
  top: 30px;
  left: 185px;
}
.herd2 {
  position: absolute;
  top: 30px;
  right: 24px;
  color: #3A3A3A ;
}
.span {
  width: 26px;
  height: 6px;
  background-color: #232323;
  border-radius: 3px;
  position: absolute;
  top: 35px;
  left: 8px;
}
.span1 {
  width: 26px;
  height: 6px;
  background-color: #232323;
  border-radius: 3px;
  position: absolute;
  top: 35px;
  left: 32px;
}
.nr1 {
  width: 100%;
  height: 820px;
}
.nr1img {
  width: 100%;
  height: 100%;
}
.nr2 {
  width: 100%;
  height: 820px;
  background-color: #eeeeee;
  line-height: 820px;
  text-align: center;
}
.nr2text {
  color: #3a3a3a;
  font-size: 30px;
}
.foot {
  width: 100%;
  height: 85px;
  background-color: #fff;
  text-align: center;
  padding-top: 30px;
}
.foot-text1,.foot-text2{
    color: #5472EA;
    font-size: 15px;
}
.foot-text1{
    
}
.foot-text2{
    margin-top: 13px;
    
}
a{
  color: #5472EA;
  text-decoration: none;
}
.foot-text3{
    font-size: 15px;
    color: #666;
    margin-top: 13px;
}
.police{
    position: relative;
    top: 3px;
}
.ys{
    color: #3A3A3A ;
}
</style>

