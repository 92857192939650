let { hostname} = window.location;
let AppInfo = {};
let INFO = {
    imgage:'https://cdn.bzxd.tv/mydemo/demoimg1.png',
    icp:'蜀ICP备2021003210号-4'
}
if(hostname.match("ryruyue")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg1.png'
  }
  if(hostname.match("mxmixin")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg2.png'
  }
  // mymeiyue
  if(hostname.match("mymeiyue")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg3.png'
    INFO.icp='蜀ICP备2021003210号-7'
  }
  //mixinmixin
  if(hostname.match("mixinmixin")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg4.png'
    INFO.icp='蜀ICP备2021003210号-5'
  }
  //mmmoyan
  if(hostname.match("mmmoyan")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg5.png'
    INFO.icp='蜀ICP备2021003210号-6'
  }
  AppInfo = { ...INFO }; 

export { AppInfo };